import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image"

import Seo from 'src/components/seo';

import s from './styles.module.css'

const Content = ({ pageContext: { title, meta_description, content, parent }, data}) => {
  let image = null
  try {
    image = data.prismicContent.data.image.localFile.childImageSharp.fluid
  } catch (e) { }
  
  return <div className={s['global']}>
    <Seo title={title} description={meta_description} />
    <div className={s['content']}>
      {image && <Img alt="Illustration" fluid={image} />}
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: content.html }} />
      
      <Link to={parent.target}>{parent.title}</Link>
    </div>
  </div>
}

export default Content


export const query = graphql`
  query GetContent($uid: String) {
    prismicContent(uid: { eq: $uid })  {
      data {
        image { localFile { childImageSharp { fluid { ...GatsbyImageSharpFluid } } } }
      }
    }
  }
`